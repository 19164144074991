import React from "react"
import { graphql, Link } from "gatsby"
import PostLink from "../components/post-link"
import Layout from '../components/layout'
import '../styles/global.css'
import Zoom from 'react-reveal/Fade' // Importing Zoom effect
import { Helmet } from "react-helmet";



export default function IndexPage () {

  return <Layout>
    <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <title>USI 4 Day Week</title>
      <meta name="description" content="We have an energetic and passionate team who take a holistic and challenge-led approach to tackling issues in the places we live, work and play." />
      <meta property="og:title" content="Urban Scale Interventions" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
      <meta property="og:image" content="" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <link rel="apple-touch-icon" sizes="180x180" href="favicon/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="favicon/favicon-16x16.png" />
      <link rel="manifest" href="favicon/site.webmanifest" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
    </Helmet>

    <div className="bg-white">
      <div className="container w-full mx-auto p-5 pb-16 py-16">
        <img className="mx-auto w-5/6 lg:w-4/6 xl:w-3/6" src="/img/fourday/YEO.gif"></img>
        <p className="text-center text-3xl text-blue-800">Introducing USI’s new approach to the working week.</p>
      </div>
      <div className="bg-fourday-blue w-full mx-auto md:py-16">
        <img className="mx-auto w-5/6 xl:w-4/6 py-16" src="/img/fourday/golden-ratio.svg"></img>
      </div>

      <div className=" w-full mx-auto">
        <img className="w-6/6 cover" src="/img/fourday/beatles.png"></img>
      </div>

      <div className="bg-white w-full mx-auto pb-16 pt-16 lg:pt-24 grid lg:grid-cols-2">
        <div className="mx-auto">
          <img className="mx-auto w-5/6 lg:w-4/6" src="/img/fourday/smart-not-longer.png"></img>
        </div>
        <div className="mx-auto p-12 lg:p-4">
          <ul className="ul-green">
            <li className="transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none  mx-auto">
              <a className="" href="https://neweconomics.org/2018/11/five-reasons-why-nef-supports-the-4-day-week-campaign" target="_blank">
                <h3 className="font-oswald text-fourday-blue font-semibold text-3xl ml-3">
                  SHORT ARTICLE
                </h3>
                <p className="pb-4 ml-3">
                  Five reasons why NEF supports the 4 Day Week Campaign<br /><span className="italic">New Economics Foundation</span>
                </p>
              </a>
            </li>

            <li className="transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none  mx-auto">
              <a href="https://www.theguardian.com/commentisfree/2016/apr/18/solution-everything-working-less-work-pressure" target="_blank">
                <h3 className="ml-3 font-oswald text-fourday-blue font-semibold text-3xl">
                  LONGER ARTICLE
                </h3>
                <p className="ml-3 pb-4">
                  The solution to (nearly) everything: working less <br /><span className="italic">Rutger Bregman | The Guardian</span>
                </p>
              </a>
            </li>

            <li className="transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none  mx-auto">
              <a href="https://neweconomics.org/2020/11/the-case-for-a-four-day-week" target="_blank">
                <h3 className=" ml-3 font-oswald text-fourday-blue font-semibold text-3xl">
                  BOOK
                </h3>
                <p className="ml-3 pb-4">
                  The case for a four-day week <br /><span className="italic">New Economics Foundation</span>
                </p>
              </a>
            </li>

            <li className="transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none  mx-auto">
              <a href="https://www.4dayweek.com/" target="_blank">
                <h3 className="ml-3 font-oswald text-fourday-blue font-semibold text-3xl">
                  GLOBAL CAMPAIGN
                </h3>
                <p className="ml-3 pb-4">
                  4 Day Week Global<br /><span className="italic">The Future of Work</span>
                </p>
              </a>
            </li>

            <li className="transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none  mx-auto">
              <a href="https://autonomy.work/portfolio/the-shorter-working-week-a-report-from-autonomy-in-collaboration-with-members-of-the-4-day-week-campaign/" target="_blank">
                <h3 className="ml-3 font-oswald text-fourday-blue font-semibold text-3xl">
                  RESEARCH REPORT
                </h3>
                <p className="ml-3 pb-4">
                  The Shorter Working Week: a report from Autonomy<br />
                </p>
              </a>
            </li>

            <li className="transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none  mx-auto">
              <a href="https://www.ted.com/talks/juliet_schor_the_case_for_a_4_day_work_week?language=en" target="_blank">
                <h3 className="ml-3 font-oswald text-fourday-blue font-semibold text-3xl">
                  TED TALK
                </h3>
                <p className="ml-3 pb-4">
                  The case for a 4-day work week<br /><span className="italic">Juliet Schor</span>
                </p>
              </a>
            </li>

          </ul>

        </div>
      </div>

      <div className=" w-full mx-auto">
        <img className="w-full" src="/img/fourday/no-loss.svg"></img>
      </div>


      <div className=" w-full mx-auto">
        <img className="w-full" src="/img/fourday/productive.svg"></img>
      </div>

      <div className="bg-fourday-blue">
        <div className=" w-11/12 mx-auto pb-16 pt-16 lg:pt-24 grid lg:grid-cols-2 items-center">
          <div className="mx-auto p-8 lg:p-16">
            <p className="pb-8 text-xl text-fourday-skyblue font-bold">But just to make sure, we’re holding an open experiment from June - September 2022. We’ll be measuring:</p>
            <ul className="ul-green">
              <li className="transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none  mx-auto">

                <p className="pb-4 ml-3 text-2xl text-white font-bold">
                  If our people are happier and healthier
                  with a better work-life balance.


                </p>

              </li>

              <li className="transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none  mx-auto">

                <p className="pb-4 ml-3 text-2xl text-white font-bold">
                  We retain our best people and
                  attract fantastic talent.


                </p>

              </li>

              <li className="transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none  mx-auto">

                <p className="pb-4 ml-3 text-2xl text-white font-bold">
                  All of our projects are delivered on time, on budget and to the same quality we are used to.


                </p>

              </li>

              <li className="transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none  mx-auto">

                <p className="pb-4 ml-3 text-2xl text-white font-bold">
                  We are recognised for new and exciting work with new clients in new markets and sectors.


                </p>

              </li>

              <li className="transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none  mx-auto">

                <p className="pb-4 ml-3 text-2xl text-white font-bold">
                  We continue to grow as an organisation, through people, projects and revenue.


                </p>

              </li>


            </ul>

          </div>
          <div className="mx-auto">
            <img className="mx-auto w-full lg:w-5/6" src="/img/fourday/rectangles.svg"></img>
          </div>

        </div>
      </div>


      <div className=" w-full bg-fourday-skyblue mx-auto py-16">
  <div className="grid md:grid-cols-2 mx-auto ">
  <div className="mx-auto font-semibold w-4/6 text-fourday-blue">
    <h3 className="font-oswald text-4xl pb-6">Intrigued?</h3>
    <p className="text-xl">Subscribe to our newsletter<br/>for updates on how it’s going!</p>
  </div>
      <div className="mx-auto w-5/6">
        <form action="https://formspree.io/f/xgepdgjd" method="POST" className="mt-6">
          <div className="sm:flex">
            <input type="email" required placeholder="Enter your email" name="email"
              className="block sm:max-w-xs w-full px-4 py-3 text-base appearance-none border border-gray-300 shadow-none bg-white rounded-md placeholder-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300"/>
            <button type="submit"
              className="mt-4 relative sm:mt-0 sm:h-auto sm:ml-4 block w-full sm:w-auto border border-transparent px-6 py-3 text-base font-semibold leading-snug bg-white text-black rounded-md shadow-md hover:bg-gray-800 focus:outline-none focus:bg-gray-800 transition ease-in-out duration-150 hover:bg-blue-100"
             >Subscribe<span/>
            </button>
          </div>
        </form>
  </div>
  </div>
</div>

<div className=" w-full bg-white mx-auto py-16 text-center">
  <div className=" mx-auto ">
  <div className="mx-auto font-semibold w-4/6">
    <h3 className="font-oswald text-fourday-skyblue text-4xl pb-10">Oh, and while<br/>you’re here...?</h3>
  </div>
  <div className="mx-auto w-5/6">
  <p className="text-sm lg:text-xl text-fourday-blue ">We’re always looking for interesting and interested people and projects.<br/>Get in touch and tell us why you want to work with us at:<br/>
  <a href="mailto:studio@urbanscaleinterventions.com"><span className="font-semibold">studio@urbanscaleinterventions.com</span></a>
  </p>
  </div>
  </div>
</div>

<div>

</div>

            <p className="mx-auto text-2xl text-fourday-blue w-4/6 lg:w-3/6 text-center pb-6">See what we’ve been working on recently:</p>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-y-6 lg:w-full xl:w-4/6 flex place-items-center m-auto">
                <Zoom>
                  <Link to='/projects/banana-block'>
                  <div className="card-container">
                    <img className="card-image transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 object-cover" src='/img/projects/bg/banana-block-camera-shot.png' alt = ""/>
                    <p className="text-left p-2 text-black">Banana Block<br/><span class="text-gray-500 leading-3"> Tourism NI Fund </span></p>
                  </div>
                  </Link>
                </Zoom>
                <Zoom>
                  <Link to='/projects/ourplaceinspace'>
                  <div className="card-container">
                    <img className="card-image transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 object-cover" src='https://unboxed2022.uk/sites/default/files/styles/uncropped_medium/public/2021-10/1.%20Derry_Londonderry%20Hero%20Graphic.jpg?itok=lES0AUam' alt = ""/>
                    <p className="text-left p-2 text-black">Our Place in Space<br/><span class="text-gray-500 leading-3"> Unboxed 2022 </span></p>
                  </div>
                  </Link>
                </Zoom>
                <Zoom>
                  <Link to='/projects/lighting'>
                  <div className="card-container">
                    <img className="card-image transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105 object-cover" src='/img/videos/lighting_projects_gif.gif' alt = ""/>
                    <p className="text-left p-2 text-black">Lighting Strategy<br/><span class="text-gray-500 leading-3"> Belfast City Council </span></p>
                  </div>
                  </Link>
                </Zoom>
            </div>


    </div>
    </Layout>
}


